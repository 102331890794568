import type { CssVarsThemeOptions } from "@mui/joy/styles";
import { extendTheme } from "@mui/joy/styles";
import { deepmerge } from "@mui/utils";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import { KeyboardArrowDown } from "@mui/icons-material";
import breakpoints from "../helpers/breakpoints";

declare module "@mui/joy/styles" {
  interface TypographySystemOverrides {
    "title-xs": true;
    kpi: true;
  }
}

const createTheme = (override?: CssVarsThemeOptions) => {
  const highDPIScreen = matchMedia(
    "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
  ).matches;
  const defaultThemeOptions: CssVarsThemeOptions = {
    shadow: {
      xs: "0px 1px 2px 0px rgba(24, 37, 51, 0.10)",
      sm: "0px 3px 4px 0px rgba(24, 37, 51, 0.04)",
      md: "0px 7px 15px 0px rgba(24, 37, 51, 0.05)",
      lg: "0px 12px 25px 0px rgba(24, 37, 51, 0.06)",
      xl: "0px 20px 40px 0px rgba(24, 37, 51, 0.07)",
    },
    colorSchemes: {
      light: {
        palette: {
          //taken from https://www.figma.com/file/HfT3a7QPgAqrOl3NjqJGTn/Prototype?node-id=1%3A515&mode=dev
          neutral: {
            50: "#FBFCFE",
            100: "#F7F9FB",
            200: "#EBEEF2",
            300: "#D6DCE2",
            400: "#C6CED6",
            500: "#A8B4C0",
            600: "#6F7C89",
            700: "#3E4B59",
            800: "#293746",
            900: "#232F3D",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainColor: "var(--joy-palette-neutral-900)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainHoverBg: "var(--joy-palette-neutral-400)",
            softBg: "var(--joy-palette-neutral-200)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          primary: {
            50: "#F7FAFE",
            100: "#DDEAFA",
            200: "#BCD5F4",
            300: "#9AC0EF",
            400: "#79ABE9",
            500: "#5796E4",
            600: "#4678B6",
            700: "#345A89",
            800: "#233C5B",
            900: "#051423",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softColor: "var(--joy-palette-primary-800)",
            softDisabledBg: "var(--joy-palette-primary-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "var(--joy-palette-neutral-100)",
            surface: "var(--joy-palette-common-white)",
            level1: "var(--joy-palette-neutral-200)",
            level2: "var(--joy-palette-neutral-300)",
            level3: "var(--joy-palette-neutral-400)",
            tooltip: "var(--joy-palette-common-white)",
          },
          text: {
            icon: "var(--joy-palette-neutral-800)",
            secondary: "var(--joy-palette-neutral-600)",
            tertiary: "var(--joy-palette-neutral-500)",
          },
          warning: {
            50: "#FEF4D6",
            100: "#FDE8AD",
            200: "#FBDD85",
            300: "#FAD66B",
            400: "#FAD15C",
            500: "#F9C633",
            600: "#C79E29",
            700: "#95771F",
            800: "#644F14",
            900: "#1D1002",
            outlinedColor: "var(--joy-palette-warning-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainColor: "var(--joy-palette-warning-700)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          success: {
            50: "#F6FEF6",
            100: "#CFF2E3",
            200: "#9FE5C7",
            300: "#6ED7AC",
            400: "#3ECA90",
            500: "#0EBD74",
            600: "#0B975D",
            700: "#087146",
            800: "#064C2E",
            900: "#2D0D13",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          danger: {
            50: "#FDEEF1",
            100: "#F9D9DF",
            200: "#F3B4BF",
            300: "#EC8EA0",
            400: "#E66980",
            500: "#E04360",
            600: "#B3364D",
            700: "#86283A",
            800: "#5A1B26",
            900: "#2D0D13",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
        },
      },
      dark: {
        palette: {
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "#182533",
            surface: "var(--joy-palette-neutral-900)",
            level1: "var(--joy-palette-neutral-800)",
            level2: "var(--joy-palette-neutral-700)",
            level3: "var(--joy-palette-neutral-600)",
            popup: "var(--joy-palette-common-white)",
            tooltip: "var(--joy-palette-common-white)",
          },
          neutral: {
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledBg: "var(--joy-palette-neutral-800)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          text: {
            icon: "var(--joy-palette-neutral-100)",
          },
          danger: {
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
          },
          success: {
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
          },
          warning: {
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
          },
          primary: {
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
          },
        },
      },
    },
    radius: {
      xs: "3px",
      sm: "7px",
      md: "14px",
      lg: "21px",
      xl: "200px",
    },
    spacing: 5,
    fontWeight: { xs: 200 },
    fontSize: {
      xl4: "2.5rem",
      xl3: "2rem",
      lg: "1rem",
      md: "0.875rem",
      sm: "0.75rem",
      xs: "0.625rem",
    },
    lineHeight: {
      xs: 1.2,
      sm: 1.2,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    typography: {
      h1: {
        letterSpacing: 0,
      },
      h2: {
        letterSpacing: 0,
      },
      h3: {
        letterSpacing: 0,
      },
      h4: {
        letterSpacing: 0,
        lineHeight: 1.2,
      },
      "title-md": {
        lineHeight: 1.2,
      },
      "title-xs": {
        fontSize: "0.625rem",
        lineHeight: 1.2,
        fontWeight: "var(--joy-fontWeight-sm)",
      },
      kpi: {
        fontSize: "5.125rem",
        fontWeight: "var(--joy-fontWeight-xs)",
        lineHeight: 1,
        letterSpacing: -0.5,
      },
    },
    components: {
      JoyDialogTitle: {
        defaultProps: {
          level: "h3",
          sx: { fontWeight: "sm" },
        },
      },
      JoySelect: {
        defaultProps: {
          indicator: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          indicator: ({ theme }) => ({
            marginRight: theme.spacing(2),
            borderRadius: theme.radius.lg,
          }),
          button: ({ theme }) => ({
            marginLeft: theme.spacing(3),
          }),
        },
      },
      JoyAutocomplete: {
        defaultProps: {
          popupIcon: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          popupIndicator: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          startDecorator: ({ theme, ownerState }) => ({
            ...(ownerState.size === "sm" && {
              marginLeft: theme.spacing(0.5),
            }),
          }),
        },
      },
      JoyButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => [
            {
              "--Button-radius": theme.radius.xl,
              "--Button-gap": theme.spacing(ownerState.size === "sm" ? 1 : 2),
              p:
                ownerState.size === "lg"
                  ? theme.spacing(4, 2)
                  : theme.spacing(3, 1),
              lineHeight: 1,
            },
            ownerState.size && {
              fontSize: theme.typography[`title-${ownerState.size}`].fontSize,
            },
            ownerState.variant === "solid" && {
              boxShadow: theme.shadow.xs,
            },
          ],
        },
      },
      JoyRadio: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            gap: theme.spacing(1),
            ...(ownerState.size === "md" && {
              gap: theme.spacing(2),
            }),
            ...(ownerState.size === "lg" && {
              gap: theme.spacing(2),
            }),
          }),
        },
      },
      JoyIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoyMenuButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
        },
      },
      JoyTable: {
        styleOverrides: {
          root: ({ theme }) => ({
            "tbody tr td:first-child": {
              borderTopLeftRadius: theme.radius.sm,
              borderBottomLeftRadius: theme.radius.sm,
            },
            "tbody tr td:last-child": {
              borderTopRightRadius: theme.radius.sm,
              borderBottomRightRadius: theme.radius.sm,
            },
            "--unstable_TableCell-height": "none",
          }),
        },
      },
      JoyInput: {
        styleOverrides: {
          root: ({ theme }) => ({
            "--Input-radius": theme.radius.xl,
          }),
        },
      },
      JoyModalClose: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoySlider: {
        styleOverrides: {
          valueLabel: {
            color: "var(--joy-palette-neutral-900)",
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: highDPIScreen ? breakpoints.highDPI.sm : breakpoints.standardDPI.sm,
        md: highDPIScreen ? breakpoints.highDPI.md : breakpoints.standardDPI.md,
        lg: highDPIScreen ? breakpoints.highDPI.lg : breakpoints.standardDPI.lg,
        xl: highDPIScreen ? breakpoints.highDPI.xl : breakpoints.standardDPI.xl,
      },
    },
  };

  return extendTheme(deepmerge(defaultThemeOptions, override));
};

export default createTheme;
